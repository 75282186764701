import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import HeaderSEO from '../components/partials/HeaderSEO'

import Layout from '../components/Layout'
const legals = () => {
  const data = useStaticQuery(graphql`
    query LegalsSEO {
      allContentfulPages(
        filter: { pageReference: { pageIdentifier: { eq: "Legals" } } }
      ) {
        edges {
          node {
            title {
              title
            }
            keywords {
              keywords
            }
            description {
              description
            }
            oGimage {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  `)

  const pageSEO = data.allContentfulPages.edges[0].node

  return (
    <Layout>
      <HeaderSEO pageSEO={pageSEO} />
      <section>
        <div className="container-1 hero-margin pb-5">
          <div className="row mb-5 pb-5 justify-content-center">
            <div className="col-md-6 mb-5 pb-5">
              <h2 className="text-center pb-5">
                Legal documents and agreements{' '}
              </h2>
              <ul className="list-style-none">
                <li className="p-2">
                  <a href="/legals/eula/">
                    {' '}
                    End User License Agreement (EULA){' '}
                  </a>
                </li>
                <li className="p-2">
                  <a href="/legals/sla/"> Service Level Agreement (SLA) </a>
                </li>
                <li className="p-2">
                  <a href="/legals/software-security-certification/">
                    {' '}
                    Software Security Certification{' '}
                  </a>
                </li>
                <li className="p-2">
                  <a href="/legals/business-continuity/">
                    {' '}
                    Business Continuity{' '}
                  </a>
                </li>
                <li className="p-2">
                  <a href="/legals/data-privacy/"> Data Privacy Notice </a>
                </li>
                <li className="p-2">
                  <a href="/legals/lenses-policies/">Other policies</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default legals
